import logo from '../graphics/logo_menu.png';
import './Menu.css';
import {Component} from 'react';

class Menu extends Component {
    state  ={ clicked: false};
    handleClick = () => {
        this.setState({clicked:
        !this.state.clicked});
        if (this.state.clicked == false){
            document.getElementById('mobile-menu').style.display = "flex";
        } else {
            document.getElementById('mobile-menu').style.display = "none";
        }
  
    }
    render() { 
        return(
            <ul className='menu'>
                <a href='/'><img className='logo_menu' src={logo}></img></a>
                 <div className='desktopNav'>
                    <li><a className='link_menu_right' href='/oferta'>Oferta</a></li>
                    <li><a className='link_menu' href='/portfolio'>Portfolio</a></li>
                    <li><a className='link_menu' href='/kontakt'>Kontakt</a></li>
                </div>   

                <div className="mobileNav" onClick={this.handleClick}>
                    <li><i className={
                        this.state.clicked ? "fa-time" : "fa-bars"
                    } ></i></li>
                    <div className="mobile-menu"id='mobile-menu'>
                        <li><a className='link_menu_right' id='link-menu-mobile' href='/oferta'>Oferta</a></li>
                        <li><a className='link_menu' id='link-menu-mobile' href='/portfolio'>Portfolio</a></li>
                        <li><a className='link_menu' id='link-menu-mobile' href='/kontakt'>Kontakt</a></li>
                    </div>
                </div>


            </ul>


        );
    }
}
export default Menu;