import React, {useState} from 'react';
import FAQ from './FAQ';
import { useNavigate } from "react-router-dom";

export default function FaqComponent (){
    
    const [faqs, setFaqs] = useState([
        {
            question: "Ile kosztuje strona internetowa?",
            answer: "Ceny naszych usług związanych z tworzeniem stron internetowych rozpoczynają się od kwoty 1500 zł. Ostateczna cena uzależniona jest od kilku kluczowych czynników, w tym złożoności projektu, specyfiki branży oraz planowanych celach biznesowych.",
            open: false
        },
        {
            question: "Ile trwa stworzenie strony?",
            answer: "Większość standardowych projektów jesteśmy w stanie wytworzyć w mniej niż dwa tygodnie.",
            open: false
        },
        {
            question: "Co, jeżeli będę potrzebował w przyszłości pomocy ze stroną?",
            answer: "Webstry bierze pełna odpowiedzialność za każdą stronę która zostaje przez nas tworzona. Po otrzymaniu twojej strony internetowej zawsze w przyszłości możesz liczyć na pomoc z naszej strony, nie zależnie czy dotyczy ona aktualizacji, zmiany czy przebudowy. ",
            open: false
        },
        {
            question: "Czy oferujecie usługi SEO podczas budowy strony",
            answer: "Tak, Webstry oferuje usługi SEO.",
            open: false
        },
        {
            question: "Czy możecie pomóc mi przebudować istniejącą stronę? ",
            answer: "Tak, z przyjemnością pomożemy ci z przebudowa lub aktualizacja strony która nie była stworzona przez nas.",
            open: false
        },
    

    ]);

    const togglleFAQ = index => {
        setFaqs(faqs.map((faq, i) => {
            if (i === index){
                faq.open = !faq.open
            } else {
                faq.open = false;
            }

            return faq;
        }))
    }

    return (
        <div className='faqs'>
            {faqs.map((faq, i) => ( 
                <div>
                    <FAQ faq={faq} index={i} toggleFAQ={togglleFAQ}/>
                </div>
                
            ))}
            
        </div>
    );
}