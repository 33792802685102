import PortfolioTemplate from '../Components/PortfolioTemplate';
import ContactPage from './ContactPage';
import FaqPage from './FaqPage';
import GoogleAds from './GoogleAds';
import HomePage from './HomePage';
import OfferPage from './OfferPage';
import DoktorEwa from './Portfolio/DoktorEwa';
import StudioTatuazu from './Portfolio/StudioTatuazu';
import TeamConnect from './Portfolio/TeamConnect';
import PortfolioPage from './PortfolioPage';
import TworzenieStron from './TworzenieStron';
import UIDesign from './UIDesign';
import OsloVestBygg from "./Portfolio/OsloVestBygg";
import PdrPartner from './Portfolio/PdrPartner';
import Recolte from "./Portfolio/Recolte";
import PolitykaPrywatnosci from './PolitykaPrywatnosci';
import ThankYouPage from './ThankYouPage';

const routes = [
	{
		path: "/",
		element: <HomePage />
	},
	{
		path: "/oferta",
		element: <OfferPage />
	},
	{
		path: "/kontakt",
		element: <ContactPage/>
	},
	{
		path: "/portfolio",
		element: <PortfolioPage/>
	},
	{
		path: "/faq",
		element: <FaqPage/>
	},
	{
		path: "/studio-tatuazu",
		element: <StudioTatuazu/>
	},
	{
		path:"/google-ads",
		element: <GoogleAds/>
	},
	{
		path:"/tworzenie-stron",
		element: <TworzenieStron/>
	},
	{
		path:"/ui-design",
		element: <UIDesign/>
	},
	{
		path:"/doktor-ewa",
		element: <DoktorEwa/>
	},
	{
		path:"team-connect",
		element:<TeamConnect/>
	},
	{
		path:"oslo-vest-bygg",
		element:<OsloVestBygg/>
	},
	{
		path:"pdr-partner",
		element:<PdrPartner/>
	},
	{
		path:"recolte",
		element:<Recolte/>
	},
	{
		path:"polityka-prywatnosci",
		element: <PolitykaPrywatnosci/>
	},
	{
		path:"thank-you",
		element: <ThankYouPage/>
	}
]

export default routes
