import React from 'react';
import * as Label from '@radix-ui/react-label';

const Field = (props) => (
  <div
    style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
    
  >
    <Label.Root className="LabelRoot" htmlFor={props.text}>
	
    </Label.Root>
    <input className="Input" type={props.type ? props.type : "text"} id={props.id} name={props.name} placeholder={props.default} onChange={(e) => props.onChange(e)} />
  </div>
);

export default Field;
