import './GlobalStyle.css';
import Menu from '../Components/Menu';
import BottomComponent from '../Components/BottomComponent';
import ServiceTogether from '../Components/ServiceTogether';
import ServiceTogetherMobile from '../Components/ServiceTogetherMobile';
import CardComponent from '../Components/CardComponent';

import Card1 from '../graphics/1small.png';
import Card2 from '../graphics/2small.png';
import Card3 from '../graphics/3small.png';
import Card4 from '../graphics/4small.png';
import image1 from '../graphics/GoogleAds.png';
import image2 from '../graphics/Web.png'
import image3 from '../graphics/UX.png'

import css from '../graphics/css.png'
import wordpress from '../graphics/wordpress.png'
import react from '../graphics/react.png'
import html from '../graphics/html.png'
import javascript from '../graphics/javascript.png'
import google from '../graphics/google.png'
import figma from '../graphics/figma.png'
import { Fade } from '@mui/material';

export default function OfferPage() {
    return(
        <div style={{overflow:"hidden"}}>
            <Menu></Menu>
            <div className='GlobalContainer'>
                <Fade in={true} timeout={2000}> 
                    <div>
                    <h2 className='textH2'> 
                    Oferta dopasowana do Ciebie
                    </h2>                
                    <h3 className='textH3'>W Webstry chcemy dostarczyć Ci najlepszy możliwy produkt. Z tego powodu przygotowaliśmy <br></br>
                        elastyczną ofertę, która dopasowuje się do Ciebie. To Ty ustalasz czego potrzebujesz i czego <br></br>
                        od nas oczekujesz. Poniżej możesz zapoznać się z naszymi typowymi usługami, ale pamiętaj, <br></br> 
                        że zawsze możesz się do nas zwrócić także z jakimś niestandardowym zleceniem.
                    </h3>
                    </div>
                </Fade>
                <Fade in={true} timeout={2000}> 
                    <section className="service-together-desktop">
                    <ServiceTogether
                    image1 = {image1}
                    image2 = {image2}
                    image3 = {image3}
                    text1 = "Zapewniamy profesjonalne usługi Google Ads, które pomogą Ci dotrzeć do szerokiej grupy klientów online. Nasz zespół dostosuje kampanie reklamowe tak, aby generowały wysoką konwersję i maksymalny zwrot z inwestycji."
                    text2 = "Oferujemy usługi tworzenia stron internetowych, które są dostosowane do Twoich unikalnych potrzeb i celów biznesowych. Nasz zespół deweloperów zapewni Ci profesjonalne rozwiązania, które wyróżnią Twoją firmę."
                    text3 = "Dostarczamy atrakcyjne i intuicyjne projekty, które zapewnią użytkownikom niezapomniane doświadczenia. Skupiamy się na tworzeniu interfejsów, które nie tylko estetycznie prezentują Twoją markę, ale także zapewniają optymalną użyteczność i łatwość nawigacji."
                    link1 = "/google-ads"
                    link2 = "/tworzenie-stron"
                    link3 = "/ui-design"
                    ></ServiceTogether>
                    </section>
                </Fade>
                <Fade in={true} timeout={2000}> 
                    <section className="service-together-mobile">
                    <ServiceTogetherMobile
                        image1 = {image1}
                        image2 = {image2}
                        image3 = {image3}
                        text1 = "Zapewniamy profesjonalne usługi Google Ads, które pomogą Ci dotrzeć do szerokiej grupy klientów online. Nasz zespół dostosuje kampanie reklamowe tak, aby generowały wysoką konwersję i maksymalny zwrot z inwestycji."
                        text2 = "Oferujemy usługi tworzenia stron internetowych, które są dostosowane do Twoich unikalnych potrzeb i celów biznesowych. Nasz zespół deweloperów zapewni Ci profesjonalne rozwiązania, które wyróżnią Twoją firmę."
                        text3 = "Jesteśmy gotowi dostarczyć Ci atrakcyjne i intuicyjne projekty, które zapewnią użytkownikom niezapomniane doświadczenia. Skupiamy się na tworzeniu interfejsów, które nie tylko estetycznie prezentują Twoją markę, ale także zapewniają optymalną użyteczność i łatwość nawigacji."
                        link1 = "/google-ads"
                        link2 = "/tworzenie-stron"
                        link3 = "/ui-design"
                        ></ServiceTogetherMobile>
                    </section>
                </Fade>
                <Fade in={true} timeout={2000}  style={{ transitionDelay: true ? '500ms' : '0ms' }}> 
                    <section>
                    <h2 className='textH2' style={{marginTop: "100px"}}>Proces projektowy nastawiony na osiągi</h2>
                    <h3 className='textH3' style={{marginBottom: "50px"}}>Do każdego zlecenia staramy się podejść tak, abyś był zadowolony i w szybkim czasie otrzymał <br></br> 
                    gotowy produkt. Dlatego maksymalnie uprościliśmy drogę, którą trzeba pokonać od <br></br> 
                    początkowych szkicy do przygotowania finalnego rozwiązania. <br></br> 
                    </h3>
                    <div className='ProcessContainer'>
                        <CardComponent
                            card={Card1}
                            width='268px' 
                            height='347px'
                            rotation='rotate(0deg)'
                            color='#8E2DE2'
                            text='Spotkamy się z Tobą oraz omówimy wszystkie kwestie projektu.'
                            fromBottom='40px'
                        ></CardComponent>
                        <CardComponent
                            card={Card2}
                            width='268px' 
                            height='347px'
                            rotation='rotate(0deg)'
                            color='#AF51B7'
                            text='Przygotujemy Twój projekt w oparciu o otrzymane od Ciebie wytyczne.'
                            fromBottom='40px'
                        ></CardComponent>
                        <CardComponent
                            card={Card3}
                            width='268px' 
                            height='347px'
                            rotation='rotate(0deg)'
                            color='#DD837C'
                            text='Naniesiemy poprawki, które zasugerujesz do swojego projektu.'
                            fromBottom='40px'
                        ></CardComponent>
                        <CardComponent
                            card={Card4}
                            width='268px' 
                            height='347px'
                            rotation='rotate(0deg)'
                            color='#FFA751'
                            text='Wdrożymy Twój projekt.'
                            fromBottom='40px'
                        ></CardComponent>
                    </div>
                    </section>
                </Fade>
                <h1 className='textH2' style={{marginTop: "100px"}}>Najnowsze rozwiązania</h1>
                <h3 className='textH3'>Nowoczesne produkty, idealnie spełniające Twoje wymagania, korzystają z najnowszych <br></br>
                     rozwiązań technologicznych. W Webstry doskonale to rozumiemy, dlatego w naszych <br></br>
                     projektach wykorzystujemy szereg nowoczesnych i sprawdzonych rozwiązań, co przekłada się <br></br>
                     na wysoką jakość oferowanych przez nas produktów.</h3>
                <table>
                    <tr>
                        <td className='technologyStack'><img src={wordpress}></img></td>
                        <td className='technologyStack'><img src={react}></img></td>
                        <td className='technologyStack'><img src={html}></img></td>
                        <td className='technologyStack'><img src={css}></img></td>
                    </tr>
                    <tr>
                        <td className='technologyStack'><img src={css}></img></td>
                        <td className='technologyStack'><img src={javascript}></img></td>
                        <td className='technologyStack'><img src={google}></img></td>
                        <td className='technologyStack'><img src={figma}></img></td>
                    </tr>
                </table>
                <BottomComponent></BottomComponent>
            </div>
          
        </div>
    );
}