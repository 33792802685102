import PortfolioTemplate from "../../Components/PortfolioTemplate";
import Visit from "../../graphics/VISIT.png";
import Contact from "../../graphics/CONTACT.png";
import Home from "../../graphics/HOME.png";
import Menu from "../../graphics/menu.png";
import Advice from "../../graphics/ADVICE.png";
import BottomComponent from "../../Components/BottomComponent";

export default function DoktorEwa () {
    return(
        <div>
            <PortfolioTemplate
            backgroundClass="portfolio-background-doktor-ewa"
            header="Redesign strony kliniki Doktor Ewy"
            paragraph1="Projekt zostal wykonany przez naszego specjaliste od UI designu. Redesign strony kliniki nie jest 
            projektem komercyjnym."
            />
            <section className="portfolio-half">
                <div className="section1">
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={Menu}></img>
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={Home}></img>
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={Contact}></img>
                </div>
                <div className="section2">
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={Visit}></img>
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={Advice}></img>
                </div>
                </section>
                <div className="GlobalContainer">
                <BottomComponent/> 
                </div>
        </div>
    );
}