import logoFooter from '../graphics/logoFooter.png';
import './BottomComponent.css';

export default function BottomComponent () {
    return(
        <footer className='MainFooter'>
            <section className='parentSection'>
                <section className="leftSide">
               
                </section>
                <section className="rightSide">
                    <table>
                        <tr>
                            <th>Odkryj</th>
                            <th>Kontakt</th>
                        </tr>
                        <tr>
                            <td><a href='/oferta'>Oferta</a></td>
                            <td>kontakt@webstry.pl</td>
                        </tr>
                        <tr>
                            <td><a href='/portfolio'>Portfolio</a></td>
                            <td>+48 572 091 023</td>
                        </tr>
                        <tr>
                            <td><a href='kontakt'>Kontakt</a></td>
                        
                        </tr>
                    </table>
                </section>
            </section>
            <a href=''><img className='img_bottom' src={logoFooter}></img></a>
            <a href=''><img className='img_mobile' src={logoFooter}></img></a>
            <div className='line'></div>
            <h4>© 2023 _webstry. Wszystkie prawa zastrzeżone.</h4>
       
        </footer>
    );
}