import Menu from '../Components/Menu';
import ServiceTogether from '../Components/ServiceTogether';
import TopPageBackground from '../graphics/TopPageBackground.png';
import BottomComponent from '../Components/BottomComponent';
import FaqComponent from '../Components/faqComponent';
import ServiceTogetherMobile from '../Components/ServiceTogetherMobile';
import './HomePage.css';
import HowWeWork from '../Components/HowWeWork';

import picture2 from '../graphics/project_head.png';
import picture3 from '../graphics/project_head-oslo.png'
import RoadmapComponent from '../Components/RoadmapComponent';
import backgroundMobile from '../graphics/mobile/backgroundMobile.png'
import { Fade } from '@mui/material';
import React, { useState, useEffect } from 'react'
import imagePDR from '../graphics/project_head-PDR-Partner.png';

  
export default function HomePage () {

    return(
        <div style={{overflowX: "hidden", overflowY: 'hidden'}}>
            <Menu></Menu>
            <div className='GlobalContainer' style={{width: '100%'}}>
                <div className='TopPage'>
                    <img src={backgroundMobile} className='mobileBackground'/>
                    <div className='TopPageLeft'>
                        <div className='TopPageEmpty'></div>
                    <div className='TopPageTextContainer'>
                    <Fade in={true} timeout={2000}> 
                        <h2 className='TopPageH2'>Zmienimy internetowy <br></br> wizerunek Twojej firmy!</h2>
                    </Fade>
                    <Fade in={true} timeout={2000}>  
                        <h3 className='TopPageH3'>Nowa strona internetowa dla Twojej marki to tylko jedna z rzeczy, <br></br>
                            które możemy zaoferować. Jesteś ciekawy w czym jeszcze możemy <br></br>
                            Ci pomóc? 
                        </h3>
                    </Fade>
                    
                    <Fade in={true} timeout={1000}  style={{ transitionDelay: true ? '500ms' : '0ms' }}>
                        <a href='/kontakt'><div className='ButtonBackground'><button className='TopPageButton' ><span  class="gradient-text">Napisz do nas</span></button></div></a>
                    </Fade>
                </div> 
                </div>
                <Fade in={true} timeout={2000}> 
                <div>
                    <img className='TopPageBackground' src={TopPageBackground}></img>
                </div>
                </Fade>
            </div>
            <Fade in={true} timeout={2000}  style={{ transitionDelay: true ? '500ms' : '0ms' }}> 
            <section className='ServicePage'>
                <div className='TopPageLeft'>
                    <div className='TopPageTextContainer'>
                        <h2 className='TopPageH2' id='TopPageH22'>Ambitne projekty i <br></br> profesjonalna współpraca</h2>
                        <h3 className='TopPageH3'>
                        Webstry to zespół młodych i ambitnych osób, którzy     <br></br>
                        pomogą Ci stworzyć stronę internetową dopasowaną do  <br></br>
                        Twoich indywidualnych potrzeb i oczekiwań. <br></br>
                        Każdy z nas pracował już przy wielu projektach, a część z nich <br></br>
                        możesz zobaczyć w naszym portfolio. <br></br>
                        </h3>
                        </div>
                    </div>

                    <div className='CenterContainer' >
                        <ServiceTogether
                        image1={imagePDR}
                        image2={picture2}
                        image3={picture3}
                        link1="./PDR-Partner"
                        link2="./studio-tatuazu"
                        link3="./oslo-vest-bygg"
                        text1="Firma PDR Partner jest warsztatem lakierniczym położonym w Oslo, Norwegii. 
                        Strona internetowa ma na celu zwiększyć  ilość klientów odwiedzających warsztat.
                        "
                        text2="Redesign stron Świat Tatuażu jest projektem graficznym przygotowanym przez naszego specjaliste UI."
                        text3="Oslo Vest Bygg AS to firma budowlana położona w Oslo, Norwegii. 
                        Strona internetowa ma na celu zwiększyć ilość zapytań o wycenę przez klientów prywatnych. 
                        "


                        ></ServiceTogether>
                    </div>
                    <div className='CenterContainer' id='mobile'>
                        <ServiceTogetherMobile
                           image1={imagePDR}
                           image2={picture2}
                           image3={picture3}
                        link1="./PDR-Partner"
                        link2="./studio-tatuazu"
                        link3="./oslo-vest-bygg"
                        text1="Firma PDR Partner jest warsztatem lakierniczym położonym w Oslo, Norwegii. 
                        Strona internetowa ma na celu zwiększyć  ilość klientów odwiedzających warsztat."
                        text2="Redesign strony Świat Tatuażu jest projektem graficznym przygotowanym przez naszego specjaliste UI."
                        text3="Oslo Vest Bygg AS to firma budowlana położona w Oslo, Norwegii. 
                        Strona internetowa ma na celu zwiększyć ilość zapytań o wycenę przez klientów prywatnych. 
                        "
                        ></ServiceTogetherMobile>
                    </div>
            </section>
            </Fade>
            <RoadmapComponent/>
            <FaqComponent/>

            <section className='GlobalContainer' style={{height:"350px"}}>
            <h1 style={{color:"white", fontSize:"45px"}}>Od czego Zaczac?</h1>
            <div style={{position:"relative", margin:"auto", marginBottom:"100px", maxWidth:"600px"}}>
            <a  href='/kontakt'><div className='ButtonBackground' style={{width:"99%", height:"75px"}}><button className='TopPageButton' style={{width:"100%", maxWidth:"600px", height:"75px"}} ><span style={{ padding:"30px"}} id='cta-button'  class="gradient-text">Skontaktuj sie z nami</span></button></div></a>
            </div>
            </section>
           

            <footer className='CenterContainer' id='bottomMobile' style={{ display: 'flex'}}>
                <BottomComponent></BottomComponent>
            </footer>
            </div>
        </div>
    );
}