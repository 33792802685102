import Menu from "../Components/Menu";
import BottomComponent from "../Components/BottomComponent"

export default function UIDesign (){
    return (
        <div>
               <div>
            <Menu/>
            <div className="GlobalContainer">
                <section style={{maxWidth:"800px", margin:"auto", color:"white"}}>
                    <h1 style={{color:"white", fontSize:"45px"}}>UI Design</h1>
                <h3 style={{fontSize:"26px"}}>Webstry to więcej niż dostawca usług internetowych - to partner w sukcesie online.</h3>
                <p style={{lineHeight:"26px", fontSize:"16px"}}>W dzisiejszym cyfrowym świecie, strona internetowa stanowi wirtualne drzwi do firmy. To pierwsze wrażenie, które sprawia, że potencjalni klienci decydują się wejść czy też odejść. W świecie online, kluczem do sukcesu jest doskonały User Interface (UI) design.</p>
                <h2 style={{marginTop:"50px"}}>Wyjątkowy Wizualny Urok</h2>
                <p style={{lineHeight:"26px", fontSize:"16px"}}>UI design to sztuka tworzenia estetycznie przyjemnego i spójnego środowiska online. W czasach, gdy użytkownicy dokonują szybkich ocen, wizualny urok Twojej strony ma ogromne znaczenie. Dzięki przemyślanemu UI designowi, możesz stworzyć wizualnie zachwycającą i łatwą do nawigacji platformę, która trzyma odwiedzających w napięciu.</p>
                <h2 style={{marginTop:"50px"}}>Bezproblemowa Ścieżka Użytkownika</h2>
                <p style={{lineHeight:"26px", fontSize:"16px"}}>Kiedy użytkownicy trafiają na Twoją stronę, oczekują płynnego i intuicyjnego doświadczenia. Efektywny UI design koncentruje się na mapowaniu ścieżki użytkownika, zapewniając, że każde kliknięcie i przewinięcie jest łatwe. To tworzy poczucie zaufania i profesjonalizmu, zwiększając szanse na przekształcenie odwiedzających w lojalnych klientów.</p>
                <h2 style={{marginTop:"50px"}}>Spójność Marki</h2>
                <p style={{lineHeight:"26px", fontSize:"16px"}}>Twoja strona internetowa jest ambasadorem marki. Skuteczny UI design zapewnia, że elementy brandingowe, od palety kolorów po typografię, są konsekwentnie i gustownie zintegrowane. To tworzy silną, zapadającą w pamięć tożsamość marki, która rezonuje z Twoją publicznością i wzmacnia wiarygodność marki.</p>
                </section>
                
                <BottomComponent/> 
                </div>
        </div>
        </div>
    );
} 