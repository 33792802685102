import PortfolioTemplate from "../../Components/PortfolioTemplate";
import home from "../../graphics/recolte-home.png";
import blog from "../../graphics/recolte-blog.png";


import BottomComponent from "../../Components/BottomComponent";

export default function DoktorEwa () {
    return(
        <div>
            <PortfolioTemplate
            backgroundClass="portfolio-background-recolte"
            header="Strona internetowa dla firmy Recolte"
            paragraph1="Recolte to firma oferujaca uslugi ocieplenia konstrukcji. Strona ma na celu zwiekszyc ilosc zapytan o wycene przez klientow."
            />
            <section className="portfolio-half">
                <div className="section1">
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={home}></img>

                </div>
                <div className="section2">
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={blog}></img>
                </div>
                </section>
                <div className="GlobalContainer">
                <BottomComponent/> 
                </div>
        </div>
    );
}