import React, { useState } from "react";
import * as Toast from '@radix-ui/react-toast';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import Field from "./field";
import { Fade } from '@mui/material';
import validator from "validator";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

 const UserForm = ({onSave, user={}}) => {
    
    //States 
    const [userData, setUserData] = useState(user);
    const [errors, setErrors] = useState({});
    const [check, setCheck] = useState(false);
    const [additionalMessage, setAdditionalMessage] = useState("TextAreaNone");
    const [open, setOpen] = React.useState(false);
    const {username, email, phone} = userData;
    const timerRef = React.useRef(0);
  

    //Checking the correctness of inputs
    const validateData = () => {
        let errors = {};
        if (!username){
            errors.name = "Imie jest wymagane";
            console.log(errors.name);
        }
        if (email) { 
            if (!validator.isEmail(email)){
                errors.email = "Poprawny Email jest wymagany";
            }
        } else {
            errors.email = "Email jest wymagany";
        }
        if (!phone) {
            errors.phone = "Telefon kontaktowy jest wymagany";
        }
        if (check == false){
            errors.checking = "Musisz potwierdzic plityke prywatności";
        }
        console.log(errors);
        return errors;
    }

    //Handling of the changes in inputs
    const handleChange = (event) => {
        const {name, value} = event.target;
        setUserData((prevData) => ({...prevData, [name]: value }));
    };
     
    //Handling of the change in checkbox
    const handleToggle = () => {
        setCheck((current) => !current);
        console.log(check);
      };

    //Saving the contact information and sending to the backend 
    const handleSave = async () => {
        const errors = validateData();
        if (Object.keys(errors).length){
            setErrors(errors);
            console.log(check)
            return;
        }
        setErrors({});
        console.log(userData);
	const response = await fetch(
		"https://webstry.pl:3001/api/client",
		{
			method: 'POST',
			body: JSON.stringify({name : userData.username, email: userData.email, phone: userData.message, message: userData.phone}),
			headers: {'Content-Type' : 'application/json'}
		}
	)
	console.log(response)
    window.location.replace('https://webstry.pl/thank-you');
        setOpen(false);
        window.clearTimeout(timerRef.current);
        timerRef.current = window.setTimeout(() => {
        setOpen(true);
        }, 100);
        onSave(userData);

    }

    //Switch 
    const toggleSwitch = () => {
        if(additionalMessage == "TextAreaNone"){
            setAdditionalMessage("TextArea");
        } else {
            setAdditionalMessage("TextAreaNone");
        }
    }

    return(
        <div className="formContainer" >
            <h1 style={{color:"white"}}>Wyślij nam swoje informacje kontaktowe</h1>
            <h2 style={{color:"white", marginBottom:"50px"}}>A my skontaktujemy się z toba.</h2>
        <div className="formContainerInner">
            <div>
                <Fade  in={true} timeout={1300}  style={{ transitionDelay: true ? '500ms' : '0ms' }}>
                <section>
                    <Field text="username" default="Imię" name="username" onChange={handleChange}/>
                        <div className="errorInformation">{errors.name}</div>
                </section>
                </Fade>
                <Fade  in={true} timeout={1300}  style={{ transitionDelay: true ? '700ms' : '0ms' }}>
                <section>
                <Field text="e-mail" default="Adres e-mail" id='emailField' name="email" onChange={handleChange}/>
                    <div className="errorInformation">{errors.email}</div>
                </section>
                </Fade>
                <Fade  in={true} timeout={1300}  style={{ transitionDelay: true ? '900ms' : '0ms' }}>
                <section>
                    <Field text="phone" default="Telefon" name="phone" onChange={handleChange}/>
                    <div className="errorInformation">{errors.phone}</div>
                </section>
                </Fade>
            </div>
		</div>
        <Fade  in={true} timeout={1300}  style={{ transitionDelay: true ? '1000ms' : '0ms' }}>
        <section className="switch-container" style={{backgroundColor:"#D2D2D2"}}>
            <FormGroup>
              <FormControlLabel control={<Switch onChange={toggleSwitch} />} label="Chcę dodatkowo wysłać wiadomość" />
            </FormGroup> 
        </section>
        </Fade>
        <Fade  in={true} timeout={1300}  style={{ transitionDelay: true ? '1100ms' : '0ms' }}>
        <textarea  className="Input" id={additionalMessage} placeholder="Jak mozemy ci pomoc?" text="message" default="Jak możemy ci pomóc?" name="message" onChange={handleChange}></textarea>
         </Fade>     
                <div style={{position:"relative", top:"52px"}} className="errorInformation">{errors.checking}</div>
                <Fade  in={true} timeout={1300}  style={{ transitionDelay: true ? '1200ms' : '0ms' }}>
                    <div style={{float: "left", display: "flex", flexDirection: "row"}}>
                        <Checkbox.Root className="CheckboxRoot" onCheckedChange={handleToggle}  id="c1" >
                        <Checkbox.Indicator className="CheckboxIndicator">
                            <CheckIcon/>
                        </Checkbox.Indicator>
                        </Checkbox.Root>
                        
                        <label className="Label" htmlFor="c1">
                            Akceptuję politykę prywatności
                        </label>
                      
                    </div>
                    </Fade>
                    
                 

                    <Toast.Provider swipeDirection="right">
                        <Fade  in={true} timeout={1300}  style={{ transitionDelay: true ? '1200ms' : '0ms' }}>
                        <div className='ButtonBackground' id="ButtonToRight" style={{width: "135px", height: "45px"}}>
                        <button className='TopPageButton' style={{width: "135px", height: "45px"}} 
                            onClick={handleSave}> 
                            Wyslij
                            </button>
                        </div>
                        </Fade>

                        <Toast.Root className="ToastRoot" open={open} onOpenChange={setOpen}>
                            <Toast.Title className="ToastTitle">Registration Successful</Toast.Title>
                            <Toast.Description asChild><div className="FormInformation">Verification code sent to {email}</div>
                            </Toast.Description>
                            <Toast.Action className="ToastAction" asChild altText="Goto schedule to undo">
                            <button className="Button small green">Close</button>
                            </Toast.Action>
                        </Toast.Root>
                        <Toast.Viewport className="ToastViewport" />
                    </Toast.Provider>

                
                    
                </div>
                
    );
}

export default UserForm;
