import ServiceComponent from "./ServiceComponent";
import image2 from '../graphics/Frame_9.png';
import './ServiceTogether.css';
import React, { useState } from "react";
export default function ServiceTogetherMobile (props) {

    return(
        <div style={{display: "flex", overflow:"hidden"}} className="service-together-container">
            <div class="containerOne" 
            style={{marginBottom:"200px"}}
            >
                    <ServiceComponent 
                    picture={props.image1}
                    text={props.text1}
                    
                        widthOuter = "100%"
                        widthText = "100%"
                        ExpandWidth = "100%"
                        OuterHeight = "200px"
                        TextOuterHeight="550px"
                        TextOuterTop = "80%"
                        TextInnerTop = "50px"
                        maxWidth = "500px"

                        link={props.link1}
                                    > 
                    </ServiceComponent>
                    
            </div>

            <div class="containerTwo"
            style={{marginBottom:"200px"}}
  
            ><ServiceComponent picture={props.image2} 
                text={props.text2}
                        widthOuter = "100%"
                        widthText = "100%"
                        ExpandWidth = "100%"
                        OuterHeight = "200px"
                        TextOuterHeight="500px"
                        TextOuterTop = "80%"
                        TextInnerTop = "50px"
                        maxWidth = "500px"
                        
                        link={props.link2}
                >
            </ServiceComponent></div>
            
            <div class="containerTwo"
            style={{marginBottom:"200px"}}

            ><ServiceComponent picture={props.image3} 
                text={props.text3}
                        widthOuter = "100%"
                        widthText = "100%"
                        ExpandWidth = "100%"
                        OuterHeight = "200px"
                        TextOuterHeight="600px"
                        TextOuterTop = "80%"
                        TextInnerTop = "50px"
                        maxWidth = "500px"

                        link = {props.link3}
                >
            </ServiceComponent></div>
            
        </div>
    );
}