import RoadmapCard from "./RoadmapCard";
import background1 from "../graphics/step1.png";
import Card2 from "../graphics/step2.png";
import Card3 from "../graphics/step3.png";
import Card4 from "../graphics/step4.png";

import one from '../graphics/1small.png';
import two from '../graphics/2small.png';
import three from '../graphics/3small.png';
import four from '../graphics/4small.png';
import vector from '../graphics/Vector2.png';
import vectorMobile from '../graphics/vertical-line.png';


export default function RoadmapComponent () {
    return (
        <div className="GlobalContainer" style={{marginBottom:"300px"}}>
            <h1 className="textH2" id="mobileH2" style={{marginBottom: "100px"}}>Jak dzialamy</h1>
        <div className="container-roadmap-mobile">
            <div>
                <img src={vectorMobile} className="vector-mobile"></img>
            </div>    
            <div className='ProcessContainer'>
                        <RoadmapCard
                            id='Card1'
                            width='260px' 
                            height='320px'
                            rotation='rotate(0deg)'
                            card={one}
                            text='Spotkamy się z Tobą oraz omówimy wszystkie kwestie projektu.'
                            fromBottom='100px'
                            bottomNumber='-20px'
                            leftNumber='-20px'
                            
                            
                        ></RoadmapCard>
                        <RoadmapCard
                            id='Card2'
                            width='260px' 
                            height='320px'
                            card={two}
                            rotation='rotate(0deg)'
                            text='Przygotujemy Twój projekt w oparciu o otrzymane od Ciebie wytyczne.'
                            fromBottom='100px'
                            bottomNumber='-20px'
                            leftNumber='-20px'
                        ></RoadmapCard>
                        <RoadmapCard
                            id='Card3'
                            width='260px' 
                            height='320px'
                            card={three}
                            rotation='rotate(0deg)'
                            text='Naniesiemy poprawki, które zasugerujesz do swojego projektu.'
                            fromBottom='100px'
                            bottomNumber='-20px'
                            leftNumber='-20px'
                        ></RoadmapCard>
                        <RoadmapCard
                            id='Card4' 
                            width='260px'
                            height='320px'
                            card={four}
                            rotation='rotate(0deg)'
                            text='Wdrożymy Twój projekt.'
                            fromBottom='100px'
                            bottomNumber='-20px'
                            leftNumber='-20px'
                        ></RoadmapCard>
                </div>
            </div>
                <img src={vector} className="vector"></img>
                </div>
    );
}