import ServiceComponent from "./ServiceComponent";
import image2 from '../graphics/Frame_9.png';
import './ServiceTogether.css';
import React, { useState } from "react";
export default function ServiceTogether (props) {

    const [objectASize, setObjectASize] = useState('260px');
    const [objectBSize, setObjectBSize] = useState('260px');
    const [objectCSize, setObjectCSize] = useState('260px');

    const [TextASize, setTextASize] = useState('260px');
    const [TextBSize, setTextBSize] = useState('260px');
    const [TextCSize, setTextCSize] = useState('260px');

    const [ExpandASize, setExpandASize] = useState('260px');
    const [ExpandBSize, setExpandBSize] = useState('260px');
    const [ExpandCSize, setExpandCSize] = useState('260px');

    return(
        <div style={{display: "flex"}}>
            <div class="containerOneSmall" 
            
            onMouseEnter={ () => {
                console.log(objectBSize);
                setObjectBSize('260px');
                setTextBSize('260px');
                setExpandBSize('260px');

                setObjectCSize('260px');
                setTextCSize('260px');
                setExpandCSize('260px');
        
                setObjectASize('575px');
                setExpandASize('50%');
                console.log(objectBSize);
            }} 
            
            onMouseLeave={ () => {
                console.log(objectBSize);
                setObjectBSize('260px');
                setTextBSize('260px');
                setExpandBSize('260px');

                setObjectCSize('260px');
                setTextCSize('260px');
                setExpandCSize('260px');

                setObjectASize('260px')
                setExpandASize('260px');
                console.log(objectBSize);
            }}

            >
                    <ServiceComponent 
                    picture={props.picture1}
                    text={props.text1}
                    
                        widthOuter = {objectASize}
                        widthText = {TextASize}
                        ExpandWidth = {ExpandASize}
                        link = {props.link1}
                                    > 
                    </ServiceComponent>
                    
            </div>

            <div class="containerTwoSmall"
            
            onMouseEnter={ () => {
                setObjectASize('260px');
                setTextASize('260px');
                setExpandASize('260px');

                setObjectCSize('260px');
                setTextCSize('260px');
                setExpandCSize('260px');
        
                setObjectBSize('575px');
                setExpandBSize('50%');
                console.log(objectBSize);
            }} 
            
            onMouseLeave={ () => {
                console.log(objectBSize);
                setObjectASize('260px');
                setTextASize('260px');
                setExpandASize('260px');

                setObjectCSize('260px');
                setTextCSize('260px');
                setExpandCSize('260px');

                setObjectBSize('260px');
                setExpandBSize('260px');
                console.log(objectBSize);
            }}
            
            ><ServiceComponent picture={props.picture2} 
                text={props.text2}
                widthOuter = {objectBSize}
                widthText = {TextBSize}
                ExpandWidth = {ExpandBSize}
                link = {props.link2}
                >
            </ServiceComponent></div>
            
            <div class="containerThreeSmall"
            
            onMouseEnter={ () => {
                setObjectASize('260px');
                setTextASize('260px');
                setExpandASize('260px');

                setObjectBSize('260px');
                setTextBSize('260px');
                setExpandBSize('260px');
        
                setObjectCSize('575px');
                setExpandCSize('50%');
                console.log(objectBSize);
            }} 
            
            onMouseLeave={ () => {
                console.log(objectBSize);
                setObjectASize('260px');
                setTextASize('260px');
                setExpandASize('260px');

                setObjectBSize('260px');
                setTextBSize('260px');
                setExpandBSize('260px');

                setObjectCSize('260px')
                setExpandCSize('260px');
                console.log(objectBSize);
            }}

            ><ServiceComponent picture={props.picture3} 
                text={props.text3}
                widthOuter = {objectCSize}
                widthText = {TextCSize}
                ExpandWidth = {ExpandCSize}
                link = {props.link3}
                >
            </ServiceComponent></div>
            
        </div>
    );
}