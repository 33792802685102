import Menu from "../Components/Menu";
import BottomComponent from "../Components/BottomComponent"

export default function GoogleAds (){
    return (
        <div>
            <Menu/>
            <div className="GlobalContainer">
                <section style={{maxWidth:"800px"}}>
                <h1 style={{color:"white", fontSize:"45px"}}>Google Ads</h1>
                <h3 style={{color:"white"}}>Wzmocnij Swoja Obecność Online z Profesjonalnym Zarządzaniem Kampaniami Google Ads!</h3>
                <h2 style={{color:"white"}}>Dlaczego Google Ads? 🌐</h2>
                <p style={{color:"white", fontSize:"16px", lineHeight:"26px"}}>W dzisiejszej epoce cyfrowej, Google to platforma, do której miliony osób zwracają się w poszukiwaniu produktów i usług. Dzięki naszej wiedzy z zakresu zarządzania reklamami Google Ads, Twój biznes może pojawić się na szczycie wyników wyszukiwania i być widoczny przez właściwe osoby w odpowiednim czasie. Chodzi o zdobycie większej ilości kliknięć, konwersji i przychodów!</p>
                <h2 style={{color:"white"}}>Dlaczego wybrac _webstry?</h2>
                <ul>
                    <li style={{color:"white", fontSize:"20px"}} >
                        Certyfikowani eksperci Google Ads
                        <p style={{fontSize:"16px" , lineHeight:"26px"}}>Nasz zespół doświadczonych profesjonalistów posiada certyfikaty w zakresie zarządzania reklamami Google, co gwarantuje, że jesteś w rękach kompetentnych specjalistów.</p>
                        </li>
                    <li style={{color:"white", fontSize:"20px", marginTop:"50px"}} >
                        Spersonalizowane strategie
                        <p style={{fontSize:"16px", lineHeight:"26px"}}>Żaden biznes nie jest identyczny, dlatego nasze strategie są dostosowane do Twoich unikalnych potrzeb i celów</p>
                        </li>
                    <li style={{color:"white", fontSize:"20px", marginTop:"50px"}} >
                        Transparentne raportowanie
                        <p style={{fontSize:"16px", lineHeight:"26px"}}>Bądź na bieżąco z wynikami Twoich kampanii dzięki szczegółowym, łatwym do zrozumienia raportom. Zawsze będziesz wiedział, na co idzie Twój budżet marketingowy.</p>
                    </li>
                    <li style={{color:"white", fontSize:"20px", marginTop:"50px"}} >
                        Stala optymalizacja
                        <p style={{fontSize:"16px", lineHeight:"26px"}}>Nie pozostawiamy kampanii samym sobie. Cały czas doskonalimy je, by wydobyć z nich maksimum potencjału.</p>
                        </li>
                </ul>
                <h2 style={{color:"white"}}>Nasz proces 📊</h2>
                <ul>
                    <li style={{color:"white", fontSize:"20px"}}>
                        Konsultacje
                        <p style={{fontSize:"16px", lineHeight:"26px"}}>Rozpoczynamy od dogłębnych konsultacji, aby zrozumieć Twoje cele biznesowe i grupę docelową.</p>
                        </li>
                    <li style={{color:"white", fontSize:"20px", marginTop:"30px"}}>
                        Rozwoj strategi
                        <p style={{fontSize:"16px", lineHeight:"26px"}}>Tworzymy strategię reklam Google Ads, starannie wybierając słowa kluczowe i tworząc przekonujący tekst reklamy.</p>
                        </li>
                    <li style={{color:"white", fontSize:"20px", marginTop:"30px"}}>
                        Uruchomienie kampanii
                        <p style={{fontSize:"16px", lineHeight:"26px"}}>Wprowadzamy Twoje kampanie, optymalizując je pod kątem maksymalnego zasięgu i efektywności kosztowej.</p>
                        </li>
                    <li style={{color:"white", fontSize:"20px", marginTop:"30px"}}>
                        Monitoring i udoskonalenie
                        <p style={{fontSize:"16px", lineHeight:"26px"}}>Cały czas śledzimy wydajność Twoich kampanii, dokonując rzeczywistych dostosowań, aby poprawić ich wyniki.</p>
                        </li>
                    <li style={{color:"white", fontSize:"20px", marginTop:"30px"}}>
                        Regularne raportowanie
                        <p style={{fontSize:"16px", lineHeight:"26px"}}>Dostarczamy szczegółowe, transparentne raporty dotyczące kluczowych wskaźników wydajności.</p>
                        </li>
                </ul>
                </section>
                <BottomComponent/> 
                </div>
        </div>
    );
} 