import BottomComponent from "../Components/BottomComponent";
import Menu from "../Components/Menu";

export default function PolitykaPrywatnosci () {
    return (
        <div>
            <Menu/>
            <section className="GlobalContainer" style={{position:"relative", top:"120px"}}>
                <section style={{color:"white", lineHeight:"2rem", fontSize:"1.3rem"}}>
                  <h2>Polityka prywatnosci</h2>
                  <h2>1. Postanowienia ogólne</h2>  
                    <li>Polityka prywatności określa, jak zbierane, przetwarzane i przechowywane są dane osobowe Użytkowników niezbędne do świadczenia usług drogą elektroniczną za pośrednictwem serwisu internetowego  (dalej: Serwis).</li>
                    <li>Serwis zbiera wyłącznie dane osobowe niezbędne do świadczenia i rozwoju usług w nim oferowanych.</li>                
                    <li>Dane osobowe zbierane za pośrednictwem Serwisu są przetwarzane zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych, dalej RODO) oraz ustawą o ochronie danych osobowych z dnia 10 maja 2018 r.</li>
                    <h2>2. Administrator danych</h2>
                    <p>Administratorem danych osobowych zbieranych poprzez Serwis jest Ernest Strychalski adres: Rakowicka 20J, KRS: [numer], NIP: [numer], REGON: [numer], adres poczty elektronicznej: ernest@webstry.pl (dalej: Administrator).	</p>
                    <h2>3. Cel zbierania danych osobowych</h2>
                    <p>1.	Dane osobowe wykorzystywane są w celu:</p>
                    <li>komunikacji z Użytkownikiem (formularz kontaktowy)</li>
                    <li>personalizacji Serwisu dla Użytkowników,</li>
                    <li>działań analitycznych i statystycznych,</li>
                    <li>ustalenia i dochodzenia roszczeń albo obrony przed nimi.</li>
                    <p>2.	Podanie danych jest dobrowolne, ale niezbędne do zawarcia umowy albo skorzystania z innych funkcjonalności Serwisu.</p>
                    <h2>4. Rodzaj przetwarzanych danych osobowych</h2>
                    <p>Administrator może przetwarzać dane osobowe Użytkownika: imię i nazwisko, adres e-mail, numer telefonu, nazwa firmy.</p>
                    <h2>5. Okres przetwarzania danych osobowych</h2>
                    <p>Dane osobowe Użytkowników będą przetwarzane przez okres:</p>
                    <li>gdy podstawą przetwarzania danych jest wykonanie umowy – do momentu przedawnienia roszczeń po jej wykonaniu,</li>
                    <li>gdy podstawą przetwarzania danych jest zgoda – do momentu jej odwołania, a po odwołaniu zgody do przedawnienia roszczeń.</li>
                    <p>W obu przypadkach termin przedawnienia wynosi 6 lat, a dla roszczeń o świadczenia okresowe i roszczeń dotyczących prowadzenia działalności gospodarczej – 3 lata (jeśli przepis szczególny nie stanowi inaczej).</p>
                    <h2>6. Udostępnianie danych osobowych</h2>
                    <p>Dane osobowe Użytkowników nie będą przekazywane poza teren Europejskiego Obszaru Gospodarczego (EOG).</p>
                    <h2>7. Prawa Użytkowników</h2>
                    <li>Użytkownik Serwisu ma prawo do: dostępu do treści swoich danych osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania, przenoszenia, wniesienia sprzeciwu wobec przetwarzania, cofnięcia zgody w każdej chwili (co nie ma wpływu na zgodność z prawem przetwarzania dokonanego w oparciu o zgodę przed jej cofnięciem).</li>
                    <li>Zgłoszenie o wystąpieniu przez Użytkownika z uprawnieniem wynikającym z wymienionych praw należy przesłać na adres ernest@webstry.pl.</li>
                    <li>Administrator spełnia lub odmawia spełnienia żądania niezwłocznie – maksymalnie w ciągu miesiąca od jego otrzymania.</li>
                    <li>Użytkownik ma prawo złożyć skargę do Prezesa Urzędu Ochrony Danych Osobowych, jeśli uzna, że przetwarzanie narusza jego prawa i wolności (RODO).</li>
                    <h2>8.	Pliki cookies</h2>
                    <li>Serwis zbiera informacje za pomocą plików cookies – sesyjnych, stałych i podmiotów zewnętrznych.</li>
                    <li>Zbieranie plików cookies wspiera poprawne świadczenie usług w Serwisie i służy celom statystycznym.</li>
                    <li>Użytkownik może określić zakres dostępu plików cookies do swojego urządzenia w ustawieniach przeglądarki.</li>
                    <h2>9. Zautomatyzowane podejmowanie decyzji i profilowanie</h2>
                    <li>Dane Użytkowników nie mogą być przetwarzane w zautomatyzowany sposób tak, że na skutek tego mogłyby zapaść wobec nich jakiekolwiek decyzje.</li>
                    <li>Dane Użytkowników mogą być profilowane celem dostosowania treści i personalizacji oferty po wyrażeniu przez nich zgody.</li>
                    <h2>10. Postanowienia końcowe</h2>
                    <li>Administrator ma prawo do wprowadzenia zmian w Polityce prywatności, przy czym prawa Użytkowników nie zostaną ograniczone.</li>
                    <li>Informacja o wprowadzonych zmianach pojawi się w formie komunikatu dostępnego w Serwisie.</li>
                    <li>W sprawach nieuregulowanych w niniejszej Polityce prywatności obowiązują przepisy RODO i przepisy prawa polskiego.</li>
                </section>
                
            <BottomComponent/>
            </section>
        </div>
    );
}