import Menu from "../Components/Menu";
import BottomComponent from "../Components/BottomComponent"

export default function TworzenieStron () {
    return (
        <div>
            <Menu/>
            <div className="GlobalContainer">
                <section style={{maxWidth:"800px", margin:"auto", color:"white"}}>
                    <h1 style={{color:"white", fontSize:"45px"}}>Tworzenie stron internetowych</h1>
                <h3>Webstry to więcej niż dostawca usług internetowych - to partner w sukcesie online.</h3>
                <p style={{lineHeight:"26px", fontSize:"16px"}}>W dzisiejszym cyfrowym świecie, obecność online jest nie tylko luksusem, ale koniecznością. Coraz więcej firm zdaje sobie sprawę z potencjału, jaki niesie ze sobą obecność w Internecie. W webstry na codziennie pomagamy przedsiębiorstwom w wykorzystaniu tego potencjału. </p>
                <p style={{lineHeight:"26px", fontSize:"16px"}}>Webstry to firma informatyczna z bogatym doświadczeniem w dziedzinie tworzenia stron internetowych. Nasze podejście do projektów internetowych opiera się na połączeniu technologii z kreatywnością, co pozwala na stworzenie stron internetowych, które nie tylko zachwycają estetyką, ale także przynoszą wymierne korzyści biznesowe.</p>
                <h2 style={{marginTop:"50px"}}>Technologie</h2>
                <p style={{lineHeight:"26px", fontSize:"16px"}}>Webstry rozumie, że nie ma jednego rozmiaru, który pasuje do wszystkich. Dlatego zespół projektowy firmy dba o to, aby dostosować technologie do konkretnych potrzeb klienta. Niezależnie od tego, czy jest to prosta strona internetowa, czy rozbudowany sklep internetowy, Webstry zawsze wybiera najlepsze narzędzia, aby dostarczyć efektywną i funkcjonalną stronę.</p>
                <h2 style={{marginTop:"50px"}}>Design z Pasja</h2>
                <p style={{lineHeight:"26px", fontSize:"16px"}}>W Webstry wierzymy, że dobra strona internetowa nie tylko informuje, ale także angażuje. Dlatego ich projektanci nie tylko dbają o estetykę, ale także o to, aby projekt strony odzwierciedlał tożsamość i wartości klienta. Każdy projekt jest tworzony z pasją i starannością, co przekłada się na unikalność i efektywność stron internetowych.</p>
                <h2 style={{marginTop:"50px"}}>Optymalizacja i Responsywność</h2>
                <p style={{lineHeight:"26px", fontSize:"16px"}}>W erze smartfonów i tabletów, responsywność jest kluczowa. Strona internetowa musi dobrze wyglądać i działać na każdym urządzeniu. Webstry zapewnia, że każda strona jest zoptymalizowana pod kątem różnych platform, co gwarantuje, że klienci mogą łatwo dostępować do treści, niezależnie od urządzenia, którego używają.</p>
                <h2 style={{marginTop:"50px"}}>Bezpieczeństwo</h2>
                <p style={{lineHeight:"26px", fontSize:"16px"}}>Bezpieczeństwo jest priorytetem dla Webstry. W dobie ciągłych zagrożeń związanych z cyberprzestępczością, firma ta gwarantuje, że każda strona internetowa jest zabezpieczona i chroniona przed atakami. Dzięki regularnym aktualizacjom i monitorowaniu, klienci mogą spać spokojnie, wiedząc, że ich strony są w bezpiecznych rękach.</p>
                <h2 style={{marginTop:"50px"}}>Sukces z Webstry</h2>
                <p style={{lineHeight:"26px", fontSize:"16px"}}>Webstry może pochwalić się imponującym portfolio projektów, które przyniosły klientom sukces i wzrost ich działalności. Dzięki zaangażowaniu, doświadczeniu i pasji zespołu Webstry, wiele firm odnotowało wzrost konwersji, lepsze pozycje w wynikach wyszukiwarek i zwiększenie przychodów.</p>
                </section>
                
                <BottomComponent/> 
                </div>
        </div>
    );
}