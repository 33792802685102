import Menu from "../Components/Menu";
import BottomComponent from '../Components/BottomComponent'
import UserForm from "../Components/UserForm";
import LeftPicture from '../graphics/Rectangle12.png';
import RightPicture from '../graphics/Rectangle12b.png';
import BigButton from "../Components/BigButton";
import BigIcon from "../graphics/bigIcon.png";
import SmallIcon from "../graphics/smallIcon.png"
import BigIcon2 from "../graphics/bigIcon2.png";
import SmallIcon2 from "../graphics/snallIcon2.png"
import { Fade } from '@mui/material';

export default function () {
    return (
        <div>
            <Menu></Menu>
            <div className='GlobalContainer'>
            <Fade  in={true} timeout={1500}>
                <div className="centeredText">
                    <h2 className="textH2" >Rozpocznij z nami swoj <br></br>
                    kolejny projekt!
                    </h2>
                    <p className="textP">Jestesmy do dyspozycji 9:00 - 18:00 od poniedzialku do piatku</p>
                </div>
            </Fade>
                <div className="InfoContainer">
                    <section className="big-button-desktop">
                    <Fade  in={true} timeout={1500}  style={{ transitionDelay: true ? '300ms' : '0ms' }}>
                    <div className="leftContactInfo">
                        <BigButton      
                            height="200px" 
                            backgroundImage={LeftPicture} 
                            BigIcon={BigIcon}
                            SmallIcon={SmallIcon}
                            BigIconLeft="55px"
                            SmallIconLeft="50px"
                            TopSmallIcon= "60px"
                            TopTitle="50px"
                            leftTitle="250px"
                            Title="Telefon"
                            Description="+48 572 091 023"
                            TopDescription="105px"
                            LeftDescription="185px"
                            ></BigButton> 
                    </div>
                    </Fade>
                    </section>
                    <div style={{width: "144px"}}></div>
                    <section className="big-button-desktop">
                    <Fade  in={true} timeout={1500}  style={{ transitionDelay: true ? '300ms' : '0ms' }}>
                    <div className="RightContactInfo">
                        <BigButton 
                            height="200px" 
                            backgroundImage={RightPicture} 
                            BigIcon={BigIcon2}
                            SmallIcon={SmallIcon2}
                            BigIconLeft="55px"
                            SmallIconLeft="50px"
                            TopSmallIcon= "60px"
                            TopTitle="50px"
                            leftTitle="250px"
                            Title="Email"
                            Description="kontakt@webstry.pl"
                            TopDescription="105px"
                            LeftDescription="185px"
                            SmallFontSize="25px"
                            ></BigButton>
                    </div>
                    </Fade>
                    </section>
                    <section className="big-button-mobile">
                    <div className="leftContactInfo">
                        <BigButton      
                            height="150px" 
                            backgroundImage={LeftPicture} 
                            BigIcon={BigIcon}  
                            BigIconWidth="85%"
                            BigIconLeft="0px"
                            SmallIconLeft="10px"
                            TopSmallIcon= "60px"
                            TopTitle="25px"
                            leftTitle="50px"
                            Title="Telefon"
                            Description="+48 572 091 023"
                            TopDescription="85px"
                            LeftDescription="18px"
                            ></BigButton>
                    </div>
                    <div style={{width: "144px"}}></div>
                    <div className="RightContactInfo">
                        <BigButton 
                            height="150px" 
                            backgroundImage={RightPicture} 
                            BigIcon={BigIcon2}
                            BigIconWidth="85%"
                            BigIconLeft="0px"
                            SmallIconLeft="10px"
                            TopSmallIcon= "60px"
                            TopTitle="25px"
                            leftTitle="50px"
                            Title="Email"
                            Description="kontakt@webstry.pl"
                            TopDescription="85px"
                            LeftDescription="10px"
                            SmallFontSize="25px"
                            ></BigButton>
                    </div>
                    </section>
                </div>
                <UserForm/>
                
                <Fade  in={true} timeout={1300}  style={{ transitionDelay: true ? '1400ms' : '0ms' }}>
                
                <section>
                    <p style={{color:"white", marginTop:"50px"}}>Wiecej o polityce prywatnosci i adminstracji danych mozesz przeczytac <a href="/polityka-prywatnosci" style={{color:"white", textDecorationLine:"underline"}}>tutaj</a></p>
                        <BottomComponent/>
                </section>
                </Fade>
            </div>
        </div>
    );
}