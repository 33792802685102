import PortfolioTemplate from "../../Components/PortfolioTemplate";
import form1 from "../../graphics/form1.png";
import form2 from "../../graphics/form2.png";
import hjem from "../../graphics/hjem-pdr-partner.png";
import kontakt from "../../graphics/kontakt-pdr-partner.png";
import omOss from "../../graphics/om-oss-pdr-partner.png"

import BottomComponent from "../../Components/BottomComponent";

export default function DoktorEwa () {
    return(
        <div>
            <PortfolioTemplate
            backgroundClass="portfolio-background-pdr-partner"
            header="Strona internetowa dla warsztatu PDR Partner"
            paragraph1="Projekt zostal wykonany w celu prezentacji marki oraz technologi PDR (Paintless Dent Repiar), 
            ktora pozwala na naprawy karoserii bez potrzeby lakierowania. Strona internetowa sluzy rowniez do zdobywania klientow prywatnych
            jak i jest swietna wizytowka firmy."
            />
            <section className="portfolio-half">
                <div className="section1">
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={hjem}></img>
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={kontakt}></img>
                </div>
                <div className="section2">
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={omOss}></img>
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={form1}></img>
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={form2}></img>
                </div>
                </section>
                <div className="GlobalContainer">
                <BottomComponent/> 
                </div>
        </div>
    );
}