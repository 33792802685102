import PortfolioTemplate from "../../Components/PortfolioTemplate";
import Visit from "../../graphics/HOME2.png";
import Contact from "../../graphics/Search.png";
import Home from "../../graphics/Search2.png";
import Menu from "../../graphics/teamconnect1.png";
import Advice from "../../graphics/teamconnect2.png";
import image6 from "../../graphics/teamconnect3.png";
import BottomComponent from "../../Components/BottomComponent";
export default function TeamConnect (){
    return(
        <div>
            <PortfolioTemplate
            backgroundClass="portfolio-background-rock-and-roll"
            header="Redesign Platformy Team Connect"
            paragraph1="Redesign strony TeamConnect jest projektem graficznym przygotowanym przez naszego grafika."
            />
            <section className="portfolio-half">
                <div className="section1">
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={Menu}></img>
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={Home}></img>
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={Contact}></img>
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={image6}></img>
                </div>
                <div className="section2">
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={Visit}></img>
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={Advice}></img>
            
                </div>
                </section>
                <div className="GlobalContainer">
                <BottomComponent/> 
                </div>
        </div>
    );
}