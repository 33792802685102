import './CardComponent.css';
import path from '../graphics/Vpath.png';
import circle from '../graphics/Ellipse195.png';
import path_connect from '../graphics/vector-line-connect.png';

export default function CardComponent(props) {
    return (
        <div class="backgroundCard"  style={{
            position: props.position,
            left: props.left
            }}>
            <div 
                class="Card" id={props.id}
                style={{
                    backgroundColor: props.color,
                    transform: props.rotation,
                    width: props.width,
                    height: props.height, 
                                   
                }}>
                <img src={props.card} class="card4" style={{
                    transform: props.rotationNumber,
                    left: props.leftNumber,
                    bottom: props.bottomNumber
                    }}></img>
                <div class="text" id='text' style={{
                    bottom: props.fromBottom,
                    fontSize: props.textSize
                }}>
                    <h3 classNme='h3Card' style={{
                        fontSize: props.size
                    }}>{props.text}</h3>
                </div>
            </div>
            <img src={path} className='Vpath'></img>
            <img src={circle} className='circle'></img>
            <img src={path_connect} className='path-connect'></img>
            <img src={circle} className='circle2'></img>
        </div>
    );
}