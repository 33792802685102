import PortfolioComponent from "../../Components/PortfolioComponent";
import PortfolioTemplate from "../../Components/PortfolioTemplate";
import BottomComponent from "../../Components/BottomComponent";

import studioHome from "../../graphics/studio_tatuazu_home.png";
import studioAboutUs from "../../graphics/studio_tatuazu_aboutus.png";
import studioFooter from "../../graphics/studio_tatuazu_footer.png";
import studioPortfolio from "../../graphics/studio_tatuazu_portfolio.png";
import studioPircing from "../../graphics/studio_tatuazu_pircing.png";

export default function StudioTatuazu(){
    return (
        <div>
            <PortfolioTemplate
            backgroundClass="portfolio-background-studio-tatuazu"
            header="Redesign strony studia tatuażu Rock’n’Roll"
            paragraph1="Redesign stron Świat Tatuażu jest projektem graficznym przygotowanym przez naszego specjaliste UI."
            />
                <section className="portfolio-half">
                <div className="section1">
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)"}} src={studioHome}></img>
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)"}} src={studioAboutUs}></img>
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)"}} src={studioFooter}></img>
                </div>
                <div className="section2">
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)"}} src={studioPortfolio}></img>
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)"}} src={studioPircing}></img>
                </div>
                </section>
                <div className="GlobalContainer">
                <BottomComponent/> 
                </div>
           
        </div>
    );
}