import Menu from "../Components/Menu";
import PortfolioComponent from "../Components/PortfolioComponent";
import ServiceTogetherMobile from "../Components/ServiceTogetherMobile";
import ServiceTogetherSmall from "../Components/ServiceTogetherSmall";
import './GlobalStyle.css';
import React, { useState, useEffect } from 'react'
import LoadingScreen from '../Components/LoadingScreen';

import image1 from '../graphics/project_head-rock-and-roll.png'
import image2 from '../graphics/project_head.png'
import image3 from '../graphics/project_head-ewa.png'
import image4 from '../graphics/project_head-PDR-Partner.png'
import image5 from '../graphics/project_head-oslo.png'
import image6 from '../graphics/project_head-recolte.png'

export default function PortfolioPage () {

    return (
        <div>
          
            <Menu></Menu>
            <div className="GlobalContainer" id='PortfolioContainer'>
  
            <ServiceTogetherSmall
            picture1 = {image4}
            picture2 = {image2}
            picture3 = {image3}
            link1 = "./pdr-partner"
            link2 = "/studio-tatuazu"
            link3 = "doktor-ewa"
            text1="Firma PDR Partner jest warsztatem lakierniczym położonym w Oslo, Norwegii. Strona internetowa ma na celu zwiększyć  ilość klientów odwiedzających warsztat."
            text2="Redesign stron Świat Tatuażu jest projektem graficznym przygotowanym przez naszego specjaliste UI."
            text3="Redesign strony Doktor Ewa jest projektem graficznym przygotowanym przez naszego specjaliste UI."
            />
            <ServiceTogetherSmall
            picture1 = {image1}
            picture2 = {image6}
            picture3 = {image5}
            link1 = "./team-connect"
            link2 = "recolte"
            link3 = "./oslo-vest-bygg"
            text1="Redesign strony TeamConnect jest projektem graficznym przygotowanym przez naszego grafika."
            text2="Recolte to firma oferujaca uslugi ocieplenia konstrukcji. Strona ma na celu zwiekszyc ilosc zapytan o wycene przez klientow."
            text3="Oslo Vest Bygg AS to firma budowlana położona w Oslo, Norwegii. Strona internetowa ma na celu zwiększyć ilość zapytań o wycenę przez klientów prywatnych. 
            "
            />
 
            </div>
            <section className="mobile-portfolio">
                <div style={{height: "138px"}}></div>
                    <ServiceTogetherMobile
                        image1 = {image4}
                        image2 = {image2}
                        image3 = {image3}
                        link1 = "./pdr-partner"
                        link2 = "/studio-tatuazu"
                        link3 = "/doktor-ewa"
                        text1="Firma PDR Partner jest warsztatem lakierniczym położonym w Oslo, Norwegii. Strona internetowa ma na celu zwiększyć  ilość klientów odwiedzających warsztat."
                        text2="Redesign stron Świat Tatuażu jest projektem graficznym przygotowanym przez naszego specjaliste UI."
                        text3="Redesign strony Doktor Ewa jest projektem graficznym przygotowanym przez naszego specjaliste UI."
                    />
                    <ServiceTogetherMobile
                        image1 = {image5}
                        image2 = {image1}
                        image3 = {image6}
                        link1 = "./oslo-vest-bygg"
                        link2 = "/team-connect"
                        link3 = "/recolte"
                        text1="Oslo Vest Bygg AS to firma budowlana położona w Oslo, Norwegii. Strona internetowa ma na celu zwiększyć ilość zapytań o wycenę przez klientów prywatnych. "
                        text2="Redesign strony TeamConnect jest projektem graficznym przygotowanym przez naszego specjaliste UI."
                    />
                </section>
        </div>
    );
}