import './CardComponent.css';
import background1 from "../graphics/step1.png";

export default function CardComponent(props) {
    return (
        <div class="backgroundCard" style={{
            position: props.position,
            left: props.left
            }}>
            <div 
                class="Card" 
                style={{
                    backgroundColor: props.color,
                    transform: props.rotation,
                    width: props.width,
                    height: props.height, 
                                   
                }}>
                <img src={props.card} class="card4" style={{
                    transform: props.rotationNumber,
                    left: props.leftNumber,
                    bottom: props.bottomNumber
                    }}></img>
                <div class="text" style={{
                    bottom: props.fromBottom,
                }}>
                    <h3 classNme='h3Card' style={{
                        fontSize: props.size
                    }}>{props.text}</h3>
                </div>
            </div>
        </div>
    );
}