import './ServiceComponent.css';
import image2 from '../graphics/Frame_9.png';

export default function ServiceComponent (props) {
    return(
        <div class="OuterExpand"
        style={{width: props.widthOuter, height: props.OuterHeight, maxWidth: props.maxWidth}}
        id={props.idComponent}
        >
            <div class="textOuter"
                style={{width: props.widthText, position:"relative", top: props.TextOuterTop, height: props.TextOuterHeight, maxWidth: props.maxWidth}}
            >
                <p class="textInner" style={{position:"relative", top: props.TextInnerTop, maxWidth: props.maxWidth}}>
                    {props.text} <div ><a style={{display: props.displayButton}} href={props.link} className='portfolio-button'>Wiecej</a></div>
                </p>
           
            </div>

            <div class="Expanding" style={{width: props.ExpandWidth, height: props.ExpandHeight, maxWidth: props.maxWidth}}>
                <img src={props.picture} class="Picture"></img>
            </div>
        </div>
        
    );
}