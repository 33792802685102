import FaqLong from "../Components/FaqLong";
import Menu from "../Components/Menu";
import BottomComponent from "../Components/BottomComponent";

export default function FaqPage () {
    return (
        <div>
            <Menu></Menu>
            <section className="GlobalContainer">
                <h1 className="textH2">FAQ Page</h1>
                <h3 className="textH3">Sekcja pytan powstala abys Ty mogl w jak najszybszy sposob otrzymac <br></br>
                                        odpowiedz na nurtujace ciebie pytanie oraz zapoznal sie lepiej z naszymi uslugami. <br></br>
                                        Jezeli nie widzisz tutaj odpowiedzi na twoje pytanie lub nie uzyskales satysfakcjonujacej odpowiedzi <br></br>
                                        Skontaktuj sie z nami.
                </h3>
                <FaqLong/>
                <BottomComponent/>
            </section>
            
        </div>
    );
}