

export default function BigButton (props){
    return (
        <div className="image-big-button"
            style={{
                width: props.width,
                height: props.height,
                display: "flex",
                
        }}>
            <img src={props.backgroundImage} style={{width:"100%"}}></img>
            <img src={props.BigIcon} style={{
                position: "absolute",
                marginLeft: props.BigIconLeft,
                width: props.BigIconWidth,
                top: props.BigIconTop
        }}></img>
            <img src={props.SmallIcon} style={{
                position: "absolute",
                marginTop: props.TopSmallIcon,
                marginLeft: props.SmallIconLeft
        }}></img>
        <h1 style={{
            position:"absolute",
            marginTop: props.TopTitle,
            marginLeft: props.leftTitle,
            fontWeight: "800",
            fontSize:"28px"
    }}>
        {props.Title}
        </h1>
        <h2 style={{
            position: "absolute",
            marginTop: props.TopDescription,
            marginLeft: props.LeftDescription,
            fontSize: props.SmallFontSize
        }}>
        {props.Description}
        </h2>
        </div>
    );
}