import ServiceComponent from "./ServiceComponent";
import image2 from '../graphics/Frame_9.png';
import './ServiceTogether.css';
import React, { useState } from "react";
export default function ServiceTogether (props) {

    const [objectASize, setObjectASize] = useState('365px');
    const [objectBSize, setObjectBSize] = useState('365px');
    const [objectCSize, setObjectCSize] = useState('365px');

    const [TextASize, setTextASize] = useState('287px');
    const [TextBSize, setTextBSize] = useState('287px');
    const [TextCSize, setTextCSize] = useState('287px');

    const [ExpandASize, setExpandASize] = useState('365px');
    const [ExpandBSize, setExpandBSize] = useState('365px');
    const [ExpandCSize, setExpandCSize] = useState('365px');

    const [displayButtonA, setDisplayButtonA] = useState("");
    const [displayButtonB, setDisplayButtonB] = useState("");
    const [displayButtonC, setDisplayButtonC] = useState("");

    return(
        <div style={{display: "flex"}} className="service-together-container">
            <div class="containerOne" 
            
            onMouseEnter={ () => {
                console.log(objectBSize);
                setObjectBSize('260px');
                setTextBSize('260px');
                setExpandBSize('260px');

                setObjectCSize('260px');
                setTextCSize('260px');
                setExpandCSize('260px');
        
                setObjectASize('575px');
                setExpandASize('50%');
                setDisplayButtonB("none");
                setDisplayButtonC("none");
                console.log(objectBSize);
            }} 
            
            onMouseLeave={ () => {
                console.log(objectBSize);
                setObjectBSize('365px');
                setTextBSize('287px');
                setExpandBSize('365px');

                setObjectCSize('365px');
                setTextCSize('287px');
                setExpandCSize('365px');

                setObjectASize('365px')
                setExpandASize('365px');
                setDisplayButtonB("");
                setDisplayButtonC("");
                console.log(objectBSize);
            }}

            >
                    <ServiceComponent 
                    picture={props.image1}
                    text={props.text1}
                    
                        widthOuter = {objectASize}
                        widthText = {TextASize}
                        ExpandWidth = {ExpandASize}
                        displayButton = {displayButtonA}
                        link = {props.link1}
                                    > 
                    </ServiceComponent>
                    
            </div>

            <div class="containerTwo"
            
            onMouseEnter={ () => {
                setObjectASize('260px');
                setTextASize('260px');
                setExpandASize('260px');

                setObjectCSize('260px');
                setTextCSize('260px');
                setExpandCSize('260px');
        
                setObjectBSize('575px');
                setExpandBSize('50%');
                setDisplayButtonA('none');
                setDisplayButtonC('none');
                console.log(objectBSize);
            }} 
            
            onMouseLeave={ () => {
                console.log(objectBSize);
                setExpandASize('365px');
                setObjectASize('365px');
                setTextASize('287px');
                
                setExpandCSize('365px');
                setObjectCSize('365px');
                setTextCSize('287px');
                

                setObjectBSize('365px');
                setExpandBSize('365px');
                setDisplayButtonA("");
                setDisplayButtonC("");
                console.log(objectBSize);
            }}
            
            ><ServiceComponent picture={props.image2} 
                text={props.text2}
                widthOuter = {objectBSize}
                widthText = {TextBSize}
                ExpandWidth = {ExpandBSize}
                displayButton = {displayButtonB}
                link = {props.link2}
                >
            </ServiceComponent></div>
            
            <div class="containerTwo"
            
            onMouseEnter={ () => {
                setObjectASize('260px');
                setTextASize('260px');
                setExpandASize('260px');

                setObjectBSize('260px');
                setTextBSize('260px');
                setExpandBSize('260px');
        
                setObjectCSize('575px');
                setExpandCSize('50%');
                setDisplayButtonA("none");
                setDisplayButtonB("none");
                console.log(objectBSize);
            }} 
            
            onMouseLeave={ () => {
                console.log(objectBSize);
                setObjectASize('365px');
                setTextASize('287px');
                setExpandASize('365px');

                setObjectBSize('365px');
                setTextBSize('287px');
                setExpandBSize('365px');

                setObjectCSize('365px')
                setExpandCSize('365px');
                setDisplayButtonA("");
                setDisplayButtonB("");
                console.log(objectBSize);
            }}

            ><ServiceComponent picture={props.image3} 
                text={props.text3}
                widthOuter = {objectCSize}
                widthText = {TextCSize}
                ExpandWidth = {ExpandCSize}
                displayButton = {displayButtonC}
                link = {props.link3}
                >
            </ServiceComponent></div>
            
        </div>
    );
}