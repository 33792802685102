import Menu from "../Components/Menu"
import BottomComponent from "../Components/BottomComponent";

export default function ThankYouPage() {
    return(
        <div>
            <Menu/>
            <div className="GlobalContainer" >
            <h1 style={{textAlign:"center", color:"white"}}>
                Formularz został pomyślnie wysłany 
            </h1>
            <h2 style={{textAlign:"center", color:"white"}}>Skontaktujemy sie z tobą w przeciągu 24h</h2>
            
            <div style={{height:"200px"}}></div>
            <BottomComponent/>
            </div>
        </div>
    );
}