import Menu from './Menu';
import BottomComponent from './BottomComponent';

export default function PortfolioTemplate (props) {
    return (
        <div>
            <Menu></Menu>
            <div className={props.backgroundClass} style={{height:"850px", maxWidth:"1440px", margin:"auto"}}></div>
            <div className='GlobalContainer'> 
                
                <h1 style={{color:"white", maxWidth:"400px"}}>{props.header}</h1>
                <p style={{color:"white", fontSize:"20px", lineHeight:"30px" , maxWidth:"700px"}}>{props.paragraph1}</p>
            </div>
            
        </div>
    );
}