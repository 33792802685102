import PortfolioTemplate from "../../Components/PortfolioTemplate";
import form from "../../graphics/form-oslo-vest-bygg.png";
import info from "../../graphics/info-oslo-vest-bygg.png";
import main from "../../graphics/main-oslo-vest-bygg.png";

import BottomComponent from "../../Components/BottomComponent";

export default function DoktorEwa () {
    return(
        <div>
            <PortfolioTemplate
            backgroundClass="portfolio-background-oslo-vest-bygg"
            header="Strona internetowa dla firmy Oslo Vest Bygg AS"
            paragraph1="Projekt zostal wykonany w celu promocji firmy dla klientow prywatnych.
            Jest to przyklad strony 'One Page' ktory pozwala na zapoznanie sie z marka oraz szybki kontakt w sprawie uslugi"
            />
            <section className="portfolio-half">
                <div className="section1">
        
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={main}></img>
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={form}></img>
                </div>
                <div className="section2">
                <img style={{position:"relative", left:"50%", transform:"translate(-50%)", width:"90%"}} src={info}></img>

                </div>
                </section>
                <div className="GlobalContainer">
                <BottomComponent/> 
                </div>
        </div>
    );
}